<template>
  <div>
    <div v-for="item in stencilList" :key="item.id">
      <div class="mainbox">
        <div class="inlinebolockbox">
          <img class="imgbox" :src="item.iconUrl" alt="" />
        </div>
        <div class="inlinebolockbox marginleft55 width800">
          <div class="namebox">
            {{ item.name }}
          </div>
          <div class="margintop20 labelone">
            <div class="inlinebolockbox width200">
              用户范围：{{ item.memberRange }}
            </div>
            <div class="inlinebolockbox">用户群：{{ item.member }}</div>
          </div>
          <div class="margintop15 labelone">
            <div class="inlinebolockbox width200">
              折扣方式：{{ item.promotionMethod | formatPromotionMethod }}
              <span class="marginleft10" v-if="item.promotionMethod == 2"
                >{{ item.costValue }}折</span
              >
              <span class="marginleft10" v-if="item.promotionMethod == 1"
                >{{ item.costValue }}元</span
              >
            </div>
            <div class="inlinebolockbox" v-if="item.member != 0">
              促销库存：{{ item.member }}
            </div>
            <div class="inlinebolockbox" v-if="item.member1 == 0">
              促销库存：无上限
            </div>
          </div>
          <div class="margintop20 labeltwo">
            预定时间：{{ item.bookDateStart }}-{{ item.bookDateEnd }},{{
              item.bookWeekdays
            }}
            <span class="marginleft20">
              入住时间：{{ item.start }}-{{ item.end }},{{ item.weekdays }}
            </span>
          </div>
        </div>
        <div class="inlinebolockbox">
          <div>
            <el-button type="text" @click="openBindDia(item.id)">绑定促销ID</el-button>
          </div>
          <div>
            <el-button type="text" @click="copyStencilData(item.id)"
              >复制</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="zhanwei1"></div>
    <el-dialog title="绑定促销ID" :visible.sync='dialogFormVisible'  width="400px">
      <div style="text-align:center;">
        ID账号 <el-input v-model="bingData.promotionId" size="mini" style="width:180px;"></el-input>
      </div>
       <div class="diafooterbtn">
          <el-button
            @click="closeFn"
            style="width: 84px; margin-right: 10%"
            size="small"
            type="primary"
            plain
            >取消</el-button
          >
          <el-button
            style="width: 84px"
            @click="handStateData"
            size="small"
            type="primary"
            >保存</el-button
          >
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPromotionTemplateList_api,
  copyPromotionTemplateInfo_api,
  bindPromotionId_api
} from "../../apis/hotelPackage";
export default {
  data() {
    return {
      stencilList: [],
      searchData: {
        pageNumber: 1,
        pageSize: 15,
        keyword: "",
      },
      bingData:{
        templateId:'',
        promotionId:''
      },
      dialogFormVisible:false,
    };
  },
  created() {
    this.getStencilList();
  },
  filters: {
    formatPromotionMethod(val) {
      if (val) {
        if (val == 1) {
          return "立减";
        } else if (val == 2) {
          return "打折";
        }
      }
    },
  },
  methods: {
    async getStencilList() {
      let { code, data } = await getPromotionTemplateList_api(this.searchData);
      if (code == 0) {
        this.stencilList = data.rows;
      }
    },
    //复制按钮
    async copyStencilData(id) {
      let { code, data } = await copyPromotionTemplateInfo_api(id);
      if (code == 0) {
        let that = this;
        let arr = data.split("<br/>");
        this.$copyText(
          `${arr[0]}\n ${arr[1]}\n ${arr[2]}\n ${arr[3]}\n ${arr[4]}\n ${arr[5]}\n ${arr[6]}\n ${arr[7]}\n ${arr[8]}\n ${arr[9]}\n ${arr[10]}\n ${arr[11]}`
        ).then((e) => {
          this.$message({
            type: "success",
            message: `${arr[0]}\n ${arr[1]}\n ${arr[2]}\n ${arr[3]}\n ${arr[4]}\n ${arr[5]}\n ${arr[6]}\n ${arr[7]}\n ${arr[8]}\n ${arr[9]}\n ${arr[10]}\n ${arr[11]}`,
          });
        });
      }
    },

    openBindDia(id){
      this.bingData.templateId=id
      this.bingData.promotionId=''
       this.dialogFormVisible=true
    },
    closeFn(){
       this.dialogFormVisible=false
    },
  async  handStateData(){
    if(this.bingData.promotionId){
      let {code,data}=await bindPromotionId_api(this.bingData)
      if(code==0){
        this.$message.success('成功绑定促销ID')
         this.dialogFormVisible=false

      }
    }else{
      this.$message.warning('请填写促销ID再绑定')
    }
    },




  },
};
</script>

<style lang="less" scoped>
.mainbox {
  margin-top: 30px;
  margin-left: 20px;
  width: 1140px;
  border-bottom: 1px solid #c9c9c9;
}
.inlinebolockbox {
  display: inline-block;
  vertical-align: top;
}
.imgbox {
  width: 190px;
  height: 130px;
}
.marginleft55 {
  margin-left: 55px;
}
.namebox {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.margintop20 {
  margin-top: 20px;
}
.labelone {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.width200 {
  width: 200px;
}
.margintop15 {
  margin-top: 15px;
}
.marginleft10 {
  margin-left: 10px;
}
.labeltwo {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.marginleft20 {
  margin-left: 20px;
}
.width800 {
  width: 820px;
}
.zhanwei1 {
  width: 100%;
  height: 100px;
}
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>

